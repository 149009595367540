import BasicSearch from "../misc/basic-search";

require("underscore");

export default class InstantSearch extends BasicSearch {
  constructor(searchUrl) {
    super(searchUrl, "", false);

    // jQuery cached elements
    this.$results_wrapper = $("#instant-search-results");
    this.$results_list = this.$results_wrapper.find("ul");
    this.$search_input = $("#search");
      this.$header_searchbar = $(".header__search-bar");
      this.$header_dropdown = $(".header__dropdown");

    this.$search_form = $("#search-form");

    this.is_fetching = false;
    this.last_query = "";

    this.initialize();
  }

  initialize() {
    // Initialize search params
    this.searchParams.amount = 6;
    this.searchParams.templateName = "suggestions";
    this.instantSearch = true;

    // Do not search for results when on mobile
    if (window.innerWidth >= 576) {
      this.$search_input.bind("paste change keyup click", () => {
        this.query = this.$search_input.val();
        setTimeout(() => {
          this.search(this.query);
        }, 200);
      });

      this.$search_input.on("focus", () => {
        this.showResults();
      });
    } else {
      this.$results_wrapper.hide();
        if (this.$header_searchbar.length > 0) {
            this.$header_searchbar.removeClass('header__search-bar--results');
            this.$header_dropdown.addClass('header__dropdown--close');
            this.$header_dropdown.removeClass('header__dropdown--open');
        }
    }
  }

  showResults() {
    if (this.$results_list.html().replace(/\s/g, "").length !== 0) {
        this.$results_wrapper.show();
        if (this.$header_searchbar.length > 0) {
            this.$header_searchbar.addClass('header__search-bar--results');
            this.$header_dropdown.removeClass('header__dropdown--close');
            this.$header_dropdown.addClass('header__dropdown--open');
        }
    }

    $(".page-wrapper").on("click", () => {
      if ($("#search").is(":focus") === false) {
        this.hideResults();
      }
    });
  }

  hideResults() {
      this.$results_wrapper.hide();
      if (this.$header_searchbar.length > 0) {
          this.$header_searchbar.removeClass('header__search-bar--results');
          this.$header_dropdown.addClass('header__dropdown--close');
          this.$header_dropdown.removeClass('header__dropdown--open');
      }
    $(".page-wrapper").off("click");
  }

  refreshComplete(query) {
    this.showResults();
    this.is_fetching = false;
    this.last_query = query;
  }

  // search
  search(query) {
    if (query) {
      if (!this.is_fetching && query !== this.last_query) {
          this.$results_wrapper.hide();
          if (this.$header_searchbar.length > 0) {
              this.$header_searchbar.removeClass('header__search-bar--results');
              this.$header_dropdown.addClass('header__dropdown--close');
              this.$header_dropdown.removeClass('header__dropdown--open');
          }
        this.$results_list.html("");
        this.is_fetching = true;
        this.loadResults(this.$results_list, _.bind(this.refreshComplete, this, query));
      }
    } else {
      this.hideResults();
    }
  }
}
