export default class Navigation {
    constructor() {
        this.$body = $('body');
        this.$pageContent = $('.page-content');

        this.menuBodyClass = 'm-menu-active';

        this.$mobileSearch = $('#m-search');
    }

    closeCartAndMenu() {
        this.$body.removeClass(this.menuBodyClass);
        this.$mobileSearch.hide();
    }

    setClickHandlers() {
        // Menu flyout
        $('#m-menu-toggle').on('click', (e) => {
            e.preventDefault();
            if (this.$body.hasClass(this.menuBodyClass)) {
                this.closeCartAndMenu();
            } else {
                this.$body.addClass(this.menuBodyClass);
                // close events
                this.$pageContent.on('click', (clickEvent) => {
                    this.closeCartAndMenu();
                    clickEvent.preventDefault();
                });
                this.$pageContent.on('swipe', (swipeEvent) => {
                    this.closeCartAndMenu();
                    swipeEvent.preventDefault();
                });
                $('#mobile-menu .flyout-close').on('click', (flyoutCloseEvent) => {
                    this.closeCartAndMenu();
                    flyoutCloseEvent.preventDefault();
                });
            }
        });

        // Searchblock
        $('#m-search-toggle').on('click', (e) => {
            e.preventDefault();
            this.$mobileSearch.toggle();
            if (this.$mobileSearch.is(':visible')) {
                this.$pageContent.on('click', (clickEvent) => {
                    this.$mobileSearch.hide();
                    clickEvent.preventDefault();
                });
                this.$mobileSearch.on('click', '#search-close', (clickEvent) => {
                    this.$mobileSearch.hide();
                    clickEvent.preventDefault();
                });
                this.$mobileSearch.on('click', '.search-btn', () => {
                    this.$mobileSearch.hide();
                });
                this.$pageContent.on('swipe', (swipeEvent) => {
                    this.$mobileSearch.hide();
                    swipeEvent.preventDefault();
                });
            }
        });
    }
}
