/* eslint no-unused-vars: 0 */
import Promise from "promise-polyfill"; // needed for IE Postalcode check!
import InstantSearch from "../misc/instant-search";
import Navigation from "../misc/navigation";
import { showProfilePopover } from "../misc/popover";
import { registerSessionTimeoutNotification } from '../misc/session-timeout-notification';
import '../misc/cookie';
import '../misc/toast';

if (!window.Promise) {
    window.Promise = Promise;
}

function getCookie(name) {
    const start = document.cookie.indexOf(`${name}=`);
    const len = start + name.length + 1;
    let end;
    if (!start && name !== document.cookie.substring(0, name.length)) {
        return null;
    }
    if (start === -1) return null;
    end = document.cookie.indexOf(";", len);
    if (end === -1) end = document.cookie.length;
    return unescape(document.cookie.substring(len, end));
}

if (getCookie("cb-enabled") === "enabled") {
    $(".hero").addClass("activeCookie");
}

$(".cb-enable").click(() => {
    $(".hero").removeClass("activeCookie");
});

// InstantSearch
window.instantSearch = new InstantSearch(window.InstantSearch_Url);

/* eslint-disable */
String.prototype.format =
    String.prototype.format ||
    function (...args) {
        let str = this.toString();
        if (args.length) {
            const t = typeof args[0];
            const functionArguments = t === "string" || t === "number" ? Array.prototype.slice.call(args) : args[0];

            for (const key in functionArguments) {
                str = str.replace(new RegExp(`\\{${key}\\}`, "gi"), functionArguments[key]);
            }
        }

        return str;
    };
/* eslint-enable */

// DOM ready
$(() => {
    const $menu = $(".sidebar-menu-to-dropdown");
    const $dropdown = $menu.find(".sidebar-menu-mobile select");

    $dropdown.on("change", event => {
        const url = event.target.value;
        window.location.href = url;
    });

    // SetClickHandlers
    const navigation = new Navigation();
    navigation.setClickHandlers();

    $.ajaxSetup({
        cache: false // Disable caching of AJAX responses
    });

    $("#language-toggle").bind("change", event => {
        window.location.replace($("#language-toggle option:selected").data("link"));
        event.preventDefault();
    });

    var accountPopover = document.getElementsByClassName("popover");
    if (accountPopover.length > 0) {
        showProfilePopover();
    }

    registerSessionTimeoutNotification();
});