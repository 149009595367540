import { createPopper } from '@popperjs/core';

export function showProfilePopover() {
    const pop = document.querySelector('[data-toggle="logged-in-popover"]');
    const ref = document.querySelector('[data-popover-ref="login"]');

    if (!pop || !ref) return;

    createPopper(ref, pop, {
        placement: 'bottom-start',
        strategy: 'fixed',
        modifiers: [
            {
                name: 'preventOverflow'
            },
            {
                name: 'offset',
                options: {
                    offset: [8, 16]
                },
            }
        ]
    });

    const popover = document.querySelector(".popover");
    setTimeout(function () {
        popover.classList.add("visible");
        setTimeout(function () {
            popover.classList.remove("visible");
            popover.style.visibility = "hidden";
        }, 3500);
    }, 2000);
}
