import { v4 as uuid } from "uuid";

const createToasterSuccessHtml = (message = '') => {
    return `
        <div class="notification notification__success notification-lg">
            <div class="notification__icon "></div>
            <div class="notification__text">${message}</div>
        </div>
    `;
}

const createToasterEl = (message = '', type = 'primary') => {
    const id = uuid();
    const el = document.createElement('div');
    el.classList.add('toaster');
    el.style.opacity = 0;
    el.setAttribute('data-id', id);

    if (type === 'success') {
        el.innerHTML = createToasterSuccessHtml(message);
    } else {
        const textEl = document.createElement('span');
        textEl.classList.add('text-' + type);
        textEl.textContent = message;
        el.appendChild(textEl);
    }

    return el;
}

const toasterEl = document.querySelector('.toaster__wrapper');

const toasts = [];

const addToast = (toast) => {
    toasts.push(toast);
};

const removeToast = (id) => {
    const index = toasts.findIndex((t) => t.id === id);
    toasts.splice(index, 1);

    // Recalculate positions of other toasts
    toasts.forEach((toast, index) => {
        const offset = toasts.reduce((offset, toast, i) => {
            if (i >= index) return offset;
            return offset + toast.element.getBoundingClientRect().height;
        }, 0);
        toast.element.style.transform = "translateY(" + offset + "px)";
    });
};


const toast = (message = "", type) => {
    const _type = type || toasterEl.dataset.success || 'primary';
    const el = createToasterEl(message, _type);
    const id = el.dataset.id;

    // calculate position
    const offset = toasts.reduce((offset, toast) => {
        return offset + toast.element.getBoundingClientRect().height;
    }, 0);

    el.style.transform = "translateY(" + offset + "px)";

    toasterEl.appendChild(el);

    // Clear CSS loop so the transition for opacity plays
    el.offsetWidth;

    el.style.opacity = 1;

    const destroy = () => {
        el.style.opacity = 0;
        removeToast(id);

        setTimeout(() => {
            el.remove();
        }, 200);
    };

    setTimeout(() => {
        destroy();
    }, 4000);

    const result = { element: el, id, destroy };
    addToast(result);
    return result;
};

// Make gobally available
window.toast = toast;