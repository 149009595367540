require('underscore');

export default class BasicSearch {
    constructor(searchUrl, query, isByEan) {
        if (typeof searchUrl === 'undefined' ||
            typeof query === 'undefined' ||
            typeof isByEan === 'undefined') {
            console.warn('WARNING! Parameters missing - Could not initialize BasicSearch.'); // eslint-disable-line no-console
            return;
        }

        this.query = query || '';
        this.instantSearch = false;
        this.searchUrl = searchUrl;
        this.searchParams = {
            templateName: '',
            amount: 30,
            page: 1,
            isByEan,
        };
    }

    // Get data from searchUrl to return
    getResults(doneCallback, failCallback) {
        const model = $.extend({ query: this.query, includestockinfo: !this.instantSearch }, this.searchParams);

        $.post(this.searchUrl, model, () => { })
            .done((result) => {
                if (_.isFunction(doneCallback)) {
                    doneCallback(result);
                } else {
                    throw 'doneCallback is not a function'; // eslint-disable-line
                }
            })
            .fail(() => {
                if (_.isFunction(failCallback)) {
                    failCallback();
                }
            });
    }

    // Get html to load into container from searchUrl
    loadResults($container, loadCallback) {
        const model = $.extend({ query: this.query, includestockinfo: !this.instantSearch }, this.searchParams);
        $container.load(this.searchUrl, model, loadCallback); // post data
    }
}
