$(() => {
    const blueconicConsentLocalStorage = JSON.parse(localStorage.getItem('bcConsentOptions'));

    const subscribeToBcEvents = () => {
        if (reset) {
            resetConsent();
        }

        window.blueConicClient.event.subscribe("bc_consent_saved", {}, function (event, context) {
            /* 
             The context parameter contains the consent data, e.g.:
             {
               consented: ["a_gebruikservaring", "b_marketing_cookies", "c_advertising"],
               refused: []
             }
            */

            postCookieConsent(context)
        });
    };

    window.addEventListener('CookiebotOnAccept', function (e) {
        postCookieConsent(null);
    }, false);

    var postCookieConsent = (context) => {
        $.post(`/CookieConsent/SetCookieConsent`, context).done(() => {
            localStorage.setItem('bcConsentOptions', JSON.stringify(context));
            sessionStorage.setItem('bcConsentSet', true);
        });

    }

    const resetConsent = () => {
        window.blueConicClient.profile.getProfile().setConsentedObjectives([]);
        window.blueConicClient.profile.getProfile().setRefusedObjectives([]);
        window.blueConicClient.profile.updateProfile();
        localStorage.removeItem('bcConsentOptions');
        sessionStorage.removeItem('bcConsentSet');
    }

    var reset = blueconicConsentLocalStorage === null || blueconicConsentLocalStorage.length === 0;

    // First check if Blueconic is Loaded, before attempting to subscribe:
    if (typeof window.blueConicClient != 'undefined' &&
        typeof window.blueConicClient.event != 'undefined' &&
        typeof window.blueConicClient.event.subscribe != "undefined") {
        // BlueConic is loaded, subscribe to its events
        subscribeToBcEvents();
    } else {
        // Wait for BlueConic to load
        window.addEventListener('onBlueConicLoaded', function () {
            // BlueConic is loaded, subscribe to its events
            subscribeToBcEvents();
        }, false);
    }

    if (!('bcConsentSet' in sessionStorage) && blueconicConsentLocalStorage != null) {
        postCookieConsent(blueconicConsentLocalStorage)
    }
})